
/* StyleClassDemo.css */


.search-field {
  width: 120px;
}

.btn-search {
  margin-top: 1.7rem;
}

.p-panel-content {
  /* background-color: var(--blue-50) !important; */
}